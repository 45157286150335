import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IResponseTypeSqlQuery } from 'app/store/slices/sql_query/type';
import * as thunks from './thunks';
import { IResponseSegmentData, SegmentType } from './type';
export interface IDatasetSliceState {
	isLoading: boolean;
	data: IResponseTypeSqlQuery | null;
	filters: IResponseTypeSqlQuery | null;
	segmentsData: IResponseSegmentData[];
	staticSegmentsData: IResponseSegmentData[];
	columnPossibleValues: IResponseTypeSqlQuery | null;
	eventsPropertiesPossibleValues: IResponseTypeSqlQuery | null;
	eventsPropertiesTypes: IResponseTypeSqlQuery | null;
}

const initialState: IDatasetSliceState = {
	isLoading: false,
	data: null,
	filters: null,
	segmentsData: [],
	staticSegmentsData: [],
	columnPossibleValues: null,
	eventsPropertiesPossibleValues: null,
	eventsPropertiesTypes: null,
};

export const datasetSlice = createSlice({
	name: 'datasetSlice',
	initialState: initialState,
	reducers: {
		setIsLoading: (state, action: PayloadAction<boolean>): IDatasetSliceState => ({
			...state,
			isLoading: action.payload,
		}),
		setData: (state, action: PayloadAction<IResponseTypeSqlQuery>): IDatasetSliceState => ({
			...state,
			data: action.payload,
		}),
		setColumnPossibleValues: (
			state,
			action: PayloadAction<IResponseTypeSqlQuery>,
		): IDatasetSliceState => ({
			...state,
			columnPossibleValues: action.payload,
		}),
		setEventsPropertiesPossibleValues: (
			state,
			action: PayloadAction<IResponseTypeSqlQuery>,
		): IDatasetSliceState => ({
			...state,
			eventsPropertiesPossibleValues: action.payload,
		}),
		setEventsPropertiesTypes: (
			state,
			action: PayloadAction<IResponseTypeSqlQuery>,
		): IDatasetSliceState => ({
			...state,
			eventsPropertiesTypes: action.payload,
		}),
		setFilters: (state, action: PayloadAction<IResponseTypeSqlQuery>): IDatasetSliceState => ({
			...state,
			filters: action.payload,
		}),
		setSegmentsData: (state, action: PayloadAction<IResponseSegmentData[]>): IDatasetSliceState => ({
			...state,
			segmentsData: action.payload.map(item => ({ ...item, type: SegmentType.Dynamic })),
		}),
		setStaticSegmentsData: (
			state,
			action: PayloadAction<IResponseSegmentData[]>,
		): IDatasetSliceState => ({
			...state,
			staticSegmentsData: action.payload.map(item => ({ ...item, type: SegmentType.Static })),
		}),
		reset: (): IDatasetSliceState => initialState,
	},
});

export const actions = datasetSlice.actions;
export const datasetReducer = datasetSlice.reducer;
export { thunks };
