import { useCallback, useState, useEffect } from 'react';
import { AutoComplete, Button, Drawer, message, Select, Space, Spin, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'app/store';
import { thunks as datasetThunks } from 'app/store/slices/dataset';
import { debounce } from 'lodash';
import {
	getEventsPropertiesOptionsFromData,
	getOperatorOptions,
	getOptionsParamsFromData,
} from 'app/utils/fieldUtils';
import {
	FilterType,
	FilterTypes,
	FilterOperators,
	PossibleValuesTableTypes,
} from 'app/pages/HomePage/components/FilterDrawer/types';
import './styles.css';
import { IResponseTypeSqlQuery } from 'app/store/slices/sql_query/type';
import { IDatabaseSetting } from 'app/store/slices/settings/type';

type Props = {
	handleClose: () => void;
	handleSubmit: (values: FilterType[], id: string) => void;
	id: string;
	openNumber: string | null;
	defaultFilters: FilterType[];
	eventType: string | null;
};

export const AddParamsDrawer = ({
	handleClose,
	handleSubmit,
	id,
	openNumber,
	defaultFilters,
	eventType,
}: Props) => {
	const dispatch = useAppDispatch();
	const [stateWhere, setStateWhere] = useState<FilterType[]>([]);
	const [stateWherePossibleValue, setWherePossibleValues] = useState<FilterType[]>([]);
	const [isSearchLoading, setIsSearchLoading] = useState(false);

	const dataEvents = useAppSelector(state => state.dataset.data);
	const eventPropertiesTypes = useAppSelector(state => state.dataset.eventsPropertiesTypes);
	const dbSettings = JSON.parse(
		localStorage.getItem('settingDatabase') || 'null',
	) as IDatabaseSetting;

	useEffect(() => {
		if (defaultFilters.length) {
			setStateWhere(defaultFilters);
		}
	}, []);
	const callbacks = {
		handleCreateWhere: useCallback(() => {
			setStateWhere([
				...stateWhere,
				{
					id: `${stateWhere.length + 1}`,
					name: '',
					value: '',
					type: FilterTypes.Text,
					operator: FilterOperators.Is,
					possibleValues: null,
				},
			]);
			setWherePossibleValues([
				...stateWherePossibleValue,
				{
					id: `${stateWhere.length + 1}`,
					name: '',
					value: '',
					type: FilterTypes.Text,
					operator: FilterOperators.Is,
					possibleValues: null,
				},
			]);
		}, [stateWhere, stateWherePossibleValue]),
		handleRemoveWhere: useCallback(
			(id: string) => {
				setStateWhere(stateWhere.filter(item => item.id !== id));
			},
			[stateWhere],
		),
		handleParamChange: useCallback(
			(valueWithType: string, eventId: string) => {
				const [value, type] = valueWithType.split('-') as [string, FilterTypes];
				const index = stateWhere.findIndex(item => item.id === eventId);
				if (index + 1) {
					const newEvents = [...stateWhere];
					newEvents[index] = { ...stateWhere[index], name: value, type: type };
					setStateWhere(newEvents);
				} else {
					message.error('Параметр не найден');
				}
			},
			[stateWhere],
		),
		handleValueChange: useCallback(
			(event: any, eventId: string) => {
				const index = stateWhere.findIndex(item => item.id === eventId);
				if (index + 1) {
					const newEvents = [...stateWhere];
					newEvents[index] = { ...stateWhere[index], value: event.target.value };
					setStateWhere(newEvents);
				} else {
					message.error('Параметр не найден');
				}
			},
			[stateWhere],
		),
		handlePossibleValuesChange: useCallback(
			(data: IResponseTypeSqlQuery | null, id: string) => {
				const index = stateWherePossibleValue.findIndex(item => item.id === id);
				if (index + 1) {
					const newFilters = [...stateWherePossibleValue];
					newFilters[index] = { ...stateWherePossibleValue[index], possibleValues: data };
					setWherePossibleValues(newFilters);
				}
			},
			[stateWherePossibleValue],
		),
		handleSelectValueChange: useCallback(
			(value: any, eventId: string) => {
				const index = stateWhere.findIndex(item => item.id === eventId);
				if (typeof value === 'string') {
					value = [value];
				}
				if (index + 1) {
					const newEvents = [...stateWhere];
					newEvents[index] = { ...stateWhere[index], value: value };
					setStateWhere(newEvents);
				} else {
					message.error('Параметр не найден');
				}
			},
			[stateWhere],
		),
		handleOperatorChange: useCallback(
			(value: FilterOperators, id: string) => {
				const index = stateWhere.findIndex(item => item.id === id);
				if (index + 1) {
					const newFilters = [...stateWhere];
					newFilters[index] = { ...stateWhere[index], operator: value };
					setStateWhere(newFilters);
				}
			},
			[stateWhere],
		),
		handleSubmit: useCallback(() => {
			handleSubmit(stateWhere, id);
			handleClose();
		}, [stateWhere]),
		handleSearch: useCallback(
			(newValue: string, fieldName: any, id: string) => {
				setIsSearchLoading(true);
				dispatch(
					datasetThunks.getPossibleValues({
						id: id,
						dbId: Number(dbSettings.db),
						tableId: Number(dbSettings.tableEventPropertiesPossibleValues),
						tableType: PossibleValuesTableTypes.EventProperty,
						fieldName: fieldName,
						newValue: newValue,
						eventType: eventType,
					}),
				)
					.unwrap()
					.then(data => callbacks.handlePossibleValuesChange(data, id))
					.then(() => setIsSearchLoading(false));
				callbacks.handlePossibleValuesChange(null, id);
			},
			[stateWhere],
		),
	};

	const getPropertyType = (item: FilterType): string | null => {
		const propertyTypeRow = eventPropertiesTypes?.data?.rows.find(eventProperty =>
			[`event_properties__${eventProperty[0]}`, `event_properties['${eventProperty[0]}']`].includes(
				item.name,
			),
		);
		return (propertyTypeRow?.[1] as string) ?? null;
	};

	const notFoundContent = () => {
		if (isSearchLoading) {
			return <Spin size='small' />;
		}
		return;
	};

	const renderParamInput = (item: FilterType) => {
		const propertyType = getPropertyType(item);
		if (propertyType && !['float', 'integer'].includes(propertyType)) {
			return (
				<Select
					showSearch
					mode='multiple'
					className='input-field-param'
					placeholder='Введите значение'
					onSearch={value => callbacks.handleSearch(value, item.name, item.id)}
					onFocus={debounce(
						() => callbacks.handleSearch(item.value ? (item.value as string) : '', item.name, item.id),
						800,
					)}
					onChange={event => callbacks.handleSelectValueChange(event, item.id)}
					notFoundContent={notFoundContent()}
					options={
						getEventsPropertiesOptionsFromData(
							stateWherePossibleValue.find(x => x.id === item.id)?.possibleValues || null,
						) || []
					}
				/>
			);
		}
		return (
			<AutoComplete
				showSearch
				className='input-field-param'
				value={String(item.value)}
				placeholder='Введите значение'
				onSearch={value => callbacks.handleSearch(value, item.name, item.id)}
				onFocus={debounce(
					() => callbacks.handleSearch(item.value ? (item.value as string) : '', item.name, item.id),
					800,
				)}
				onChange={event => callbacks.handleSelectValueChange(event, item.id)}
				notFoundContent={notFoundContent()}
				options={
					getEventsPropertiesOptionsFromData(
						stateWherePossibleValue.find(x => x.id === item.id)?.possibleValues || null,
					) || []
				}
			/>
		);
	};

	return (
		<Drawer
			title='Параметры'
			width={700}
			onClose={handleClose}
			open={openNumber === id}
			extra={
				<Space>
					<Button onClick={handleClose}>Отмена</Button>
					<Button type='primary' onClick={callbacks.handleSubmit}>
						Сохранить
					</Button>
				</Space>
			}
		>
			<div className='container__select-field-param'>
				{stateWhere.map(item => (
					<div className='field-container' key={item.id}>
						<Select
							className='select-field-param'
							showSearch
							placeholder='Выберите параметр'
							optionFilterProp='children'
							onChange={value => callbacks.handleParamChange(value, item.id)}
							filterOption={(input, option) =>
								(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
							}
							options={getOptionsParamsFromData(dataEvents, eventType, eventPropertiesTypes) || []}
						/>
						<Select
							className='select-field-operator'
							placeholder='Выберите оператор'
							defaultValue={FilterOperators.Is}
							onChange={value => callbacks.handleOperatorChange(value, item.id)}
							options={getOperatorOptions(item)}
						/>
						{renderParamInput(item)}
						<Button
							className='remove-action-with-field'
							title='Удалить условие'
							onClick={() => callbacks.handleRemoveWhere(item.id)}
							icon={<DeleteOutlined />}
						/>
					</div>
				))}
			</div>
			{eventType ? (
				<Button onClick={callbacks.handleCreateWhere}>Добавить</Button>
			) : (
				<Typography.Text type='warning'>
					Для добавления параметров необходимо выбрать событие
				</Typography.Text>
			)}
		</Drawer>
	);
};
