import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EventType, FunnelType } from 'app/pages/HomePage/components/EventList/types';
import { FilterType } from 'app/pages/HomePage/components/FilterDrawer/types';
import { IResponseSegmentData } from '../dataset/type';

interface IEventSliceState {
	isLoading: boolean;
	events: EventType[];
	funnel: FunnelType;
	database: string;
	eventsOrder: string;
	metric: string;
	filters: FilterType[];
	groupby: FilterType[];
	segments: IResponseSegmentData[];
}

const initialState: IEventSliceState = {
	isLoading: false,
	events: [],
	filters: [],
	groupby: [],
	segments: [],
	database: '1',
	eventsOrder: 'straight',
	metric: 'funnel',
	funnel: {
		value: '1',
		granularity: 'days',
	},
};

export const eventSlice = createSlice({
	name: 'eventSlice',
	initialState: initialState,
	reducers: {
		setIsLoading: (state, action: PayloadAction<boolean>): IEventSliceState => ({
			...state,
			isLoading: action.payload,
		}),
		setEvents: (state, action: PayloadAction<EventType[]>) => {
			return { ...state, events: action.payload };
		},
		addEvent: state => {
			return {
				...state,
				events: [...state.events, { id: `${state.events.length + 1}`, value: '', filters: [] }],
			};
		},
		changeEventFilter: (state, action: PayloadAction<{ value: FilterType[]; eventId: string }>) => {
			const index = state.events.findIndex(item => item.id === action.payload.eventId);
			if (index + 1) {
				const newEvents = [...state.events];
				newEvents[index] = { ...state.events[index], filters: action.payload.value };
				return { ...state, events: newEvents };
			}
			return state;
		},
		setFunnel: (state, action: PayloadAction<FunnelType>) => {
			return { ...state, funnel: action.payload };
		},
		setDatabase: (state, action: PayloadAction<string>) => {
			return { ...state, database: action.payload };
		},
		setEventsOrder: (state, action: PayloadAction<string>) => {
			return { ...state, eventsOrder: action.payload };
		},
		setMetric: (state, action: PayloadAction<string>) => {
			return { ...state, metric: action.payload };
		},
		setFilter: (state, action: PayloadAction<FilterType[]>) => {
			return { ...state, filters: action.payload };
		},
		setGroupBy: (state, action: PayloadAction<FilterType[]>) => {
			return { ...state, groupby: action.payload };
		},
		setSegments: (state, action: PayloadAction<IResponseSegmentData[]>) => {
			return { ...state, segments: action.payload };
		},
		reset: (): IEventSliceState => initialState,
	},
});

export const actions = eventSlice.actions;
export const eventReducer = eventSlice.reducer;
