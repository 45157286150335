import { FilterType } from 'app/pages/HomePage/components/FilterDrawer/types';

export type EventType = { id: string; value: string; filters: FilterType[]; type?: string };

export type FunnelType = { value: string; granularity: string };

export enum MetricTypes {
	Funnel = 'funnel',
	OverTime = 'over_time',
	TimeToConvert = 'time_to_convert',
}