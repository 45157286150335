import axios from 'axios';
import { IRequestSaveValues, IResponseTypeField, IResponseTypeSqlQuery } from './type';
import { v4 as uuidv4 } from 'uuid';

export const executeSqlQuery = async (
	sqlQuery: string,
	db: string,
): Promise<IResponseTypeSqlQuery> => {
	return axios
		.post('api/dataset', {
			type: 'native',
			native: {
				query: sqlQuery,
				'template-tags': {},
			},
			database: Number(db),
			parameters: [],
		})
		.then(function (response) {
			return response.data as IResponseTypeSqlQuery;
		})
		.catch(function (error) {
			throw error;
		});
};

export const getFieldById = async (
	fieldId: number,
): Promise<IResponseTypeField> => {
	return axios
		.get(`api/field/${fieldId}`)
		.then(function (response) {
			return response.data as IResponseTypeField;
		})
		.catch(function (error) {
			throw error;
		});
};

export const saveQuestion = async ({
	name,
	description,
	display = 'funnel',
	visualization_settings = {},
	collectionId = null,
	database_id,
	date_field_id = null,
	datePartitionValue = null,
	sqlQuery,
}: IRequestSaveValues & {
	database_id?: number;
	date_field_id?: number | null;
	datePartitionValue?: string | null;
	sqlQuery: string;
}): Promise<IRequestSaveValues> => {
	const uuid = uuidv4();
	return axios
		.post('api/card', {
			name: name,
			dataset_query: {
				database: database_id,
				native: {
					query: sqlQuery,
					'template-tags': sqlQuery.includes('{{date}}')
						? {
								date: {
									id: uuid,
									name: 'date',
									'display-name': 'Date',
									type: 'dimension',
									dimension: ['field', date_field_id, null],
									'widget-type': 'date/all-options',
									default: datePartitionValue,
								},
						  }
						: {},
				},
				type: 'native',
			},
			display: display,
			description: description,
			visualization_settings: visualization_settings,
			parameters: sqlQuery.includes('{{date}}')
				? [
						{
							id: uuid,
							type: 'date/all-options',
							target: ['dimension', ['template-tag', 'date']],
							name: 'Date',
							slug: 'date',
						},
				  ]
				: [],
			collection_id: collectionId === null ? collectionId : Number(collectionId),
			collection_position: null,
			result_metadata: null,
		})
		.then(function (response) {
			return response.data as IRequestSaveValues;
		})
		.catch(function (error) {
			throw error;
		});
};
