import { useCallback, useState } from 'react';
import { Badge, Button, Drawer, Select, Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import {
	FilterType,
	FilterTypes,
	FilterOperators,
} from 'app/pages/HomePage/components/FilterDrawer/types';
import { getOptionsFromColumns, getGroupGranularityOptions } from 'app/utils/fieldUtils';
import { useAppSelector } from 'app/store';
import './styles.css';

type Props = {
	onSubmit: (value: FilterType[]) => void;
	stateIsOpen: boolean;
	setStateIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	};
	
	

export const GroupDrawer = ({ onSubmit, stateIsOpen, setStateIsOpen }: Props) => {
	const [stateFilter, setStateFilter] = useState<FilterType[]>([]);
	const filters = useAppSelector(state => state.dataset.filters);

	const callbacks = {
		handleOpen: useCallback(() => {
			setStateIsOpen(true);
		}, []),
		handleClose: useCallback(() => {
			setStateIsOpen(false);
		}, []),
		handleCreateGroupBy: useCallback(() => {
			setStateFilter([
				...stateFilter,
				{
					id: `${stateFilter.length + 1}`,
					name: '',
					value: '',
					operator: FilterOperators.Is,
					type: FilterTypes.Text,
					granularity: 'toDate',
				},
			]);
		}, [stateFilter]),
		handleRemoveGroupBy: useCallback(
			(id: string) => {
				setStateFilter(stateFilter.filter(item => item.id !== id));
			},
			[stateFilter],
		),
		handleNameChange: useCallback(
			(valueWithType: string, id: string) => {
				const [value, type] = valueWithType.split('-') as [string, FilterTypes];
				const index = stateFilter.findIndex(item => item.id === id);
				if (index + 1) {
					const newFilters = [...stateFilter];
					newFilters[index] = { ...stateFilter[index], name: value, type: type };
					setStateFilter(newFilters);
				}
			},
			[stateFilter],
		),
		handleGranularityChange: useCallback(
			(value: string, id: string) => {
				const index = stateFilter.findIndex(item => item.id === id);
				if (index + 1) {
					const newFilters = [...stateFilter];
					newFilters[index] = { ...stateFilter[index], granularity: value };
					setStateFilter(newFilters);
				}
			},
			[stateFilter],
		),
		handleInputValueChange: useCallback(
			(event: any, id: string) => {
				const index = stateFilter.findIndex(item => item.id === id);
				if (index + 1) {
					const newFilters = [...stateFilter];
					newFilters[index] = { ...stateFilter[index], value: event.target.value };
					setStateFilter(newFilters);
				}
			},
			[stateFilter],
		),
		handleSubmit: useCallback(() => {
			onSubmit(stateFilter);
			setStateIsOpen(false);
		}, [stateFilter, onSubmit]),
	};
	return (
		<>
			<Badge size='small' count={stateFilter.length}>
				<Button onClick={callbacks.handleOpen} className='add-button'>
					Добавить группировку
				</Button>
			</Badge>
			<Drawer
				open={stateIsOpen}
				title='Группировки'
				onClose={callbacks.handleClose}
				width={700}
				extra={
					<Space>
						<Button type='primary' onClick={callbacks.handleSubmit}>
							Сохранить
						</Button>
					</Space>
				}
			>
				<div className='container__select-field-param'>
					{stateFilter.map(item => {
						return (
							<div className='field-container' key={item.id}>
								<Select
									className='select-field-group-by-param'
									showSearch
									placeholder='Выберите поле группировки'
									optionFilterProp='children'
									onChange={value => callbacks.handleNameChange(value, item.id)}
									filterOption={(input, option) =>
										(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
									}
									options={getOptionsFromColumns(filters?.data.cols) || []}
								/>
								{item.type === 'type/Date' && (
									<Select
										className='select-field-group-by-param'
										placeholder='Выберите грануляцию'
										defaultValue={getGroupGranularityOptions()[1]['value']}
										onChange={value => callbacks.handleGranularityChange(value, item.id)}
										options={getGroupGranularityOptions().slice(1)}
									/>
								)}
								{item.type === 'type/DateTime' && (
									<Select
										className='select-field-group-by-param'
										placeholder='Выберите грануляцию'
										defaultValue={getGroupGranularityOptions()[1]['value']}
										onChange={value => callbacks.handleGranularityChange(value, item.id)}
										options={getGroupGranularityOptions()}
									/>
								)}
								<Button
									className='remove-action-with-field'
									title='Удалить группировку'
									onClick={() => callbacks.handleRemoveGroupBy(item.id)}
									icon={<DeleteOutlined />}
								/>
							</div>
						);
					})}
				</div>
				<Button onClick={callbacks.handleCreateGroupBy}>Добавить</Button>
			</Drawer>
		</>
	);
};
