import { Button, Input, List, Select, Spin, Typography, message } from 'antd';
import { EventCard } from 'app/pages/HomePage/components/EventCard';
import { FilterDrawer } from 'app/pages/HomePage/components/FilterDrawer';
import { FilterType } from 'app/pages/HomePage/components/FilterDrawer/types';
import { GroupDrawer } from 'app/pages/HomePage/components/GroupDrawer';
import { SaveModalForm } from 'app/pages/HomePage/components/SaveModalForm';
import { SegmentsDrawer } from 'app/pages/HomePage/components/SegmentsDrawer';
import { ShowQuestionButton } from 'app/pages/HomePage/components/ShowQuestionButton';
import { useAppDispatch, useAppSelector } from 'app/store';
import { IResponseDatabaseData } from 'app/store/slices/database/type';
import { actions } from 'app/store/slices/events';
import { actions as sqlQueryActions, thunks as sqlQueryThunks } from 'app/store/slices/sql_query';
import { getFunnelGranularityOptions, getOptions } from 'app/utils/fieldUtils';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import './styles.css';
import { EventType, MetricTypes } from './types';
import { IResponseSegmentData } from 'app/store/slices/dataset/type';

export const EventList = () => {
	const {
		isLoading: isLoadingSqlQuery,
		sqlQuery,
		data: sqlResponseData,
		questionId,
	} = useAppSelector(state => state.sqlQuery);
	const { isLoading: isLoadingDataset } = useAppSelector(state => state.dataset);
	const { data } = useAppSelector(state => state.database);
	const dataEvents = useAppSelector(state => state.dataset.data);
	const [stateGroupByIsOpen, setStateGroupByIsOpen] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	const isLoadingData = isLoadingSqlQuery || isLoadingDataset;
	const { events, filters, funnel, database } = useAppSelector(state => state.event);
	useEffect(() => {
		const databaseSource = localStorage.getItem('databaseSource');
		if (databaseSource) {
			dispatch(actions.setDatabase(databaseSource));
		}
	}, []);

	const callbacks = {
		handleSetEvents: useCallback(
			(values: EventType[]) => {
				dispatch(actions.setEvents(values));
				dispatch(sqlQueryThunks.getSqlQuery());
			},
			[events],
		),
		handleAddEvent: useCallback(() => {
			dispatch(actions.addEvent());
		}, [events]),
		handleExecuteQuery: useCallback(async () => {
			await dispatch(sqlQueryThunks.executeSqlQuery());
			dispatch(sqlQueryActions.setVisualizationStateOpenId('visualization'));
		}, [events, funnel, database, filters]),
		handleFunnelChange: useCallback(
			(e: any) => {
				const value = e.target.value;
				const newFunnel = { ...funnel, value: value };
				dispatch(actions.setFunnel(newFunnel));
				dispatch(sqlQueryThunks.getSqlQuery());
			},
			[funnel],
		),
		handleFunnelGranularityChange: useCallback(
			(granularity: string) => {
				const newFunnel = { ...funnel, granularity: granularity };
				dispatch(actions.setFunnel(newFunnel));
				dispatch(sqlQueryThunks.getSqlQuery());
			},
			[funnel],
		),
		handleDbChange: useCallback((value: string) => {
			dispatch(actions.setDatabase(value));
			dispatch(sqlQueryThunks.getSqlQuery());
			localStorage.setItem('databaseSource', value);
		}, []),
		handleEventsOrderChange: useCallback((value: string) => {
			dispatch(actions.setEventsOrder(value));
			dispatch(sqlQueryThunks.getSqlQuery());
		}, []),
		handleMetricsChange: useCallback((value: string) => {
			if (value === MetricTypes.OverTime) {
				setStateGroupByIsOpen(true)
				message.info("Добавьте группировку по date_partition!")
			}
			dispatch(actions.setMetric(value));
			dispatch(sqlQueryThunks.getSqlQuery());
		}, []),
		handleSaveFilters: useCallback((value: FilterType[]) => {
			dispatch(actions.setFilter(value));
			dispatch(sqlQueryThunks.getSqlQuery());
		}, []),
		handleSaveGroupBy: useCallback((value: FilterType[]) => {
			dispatch(actions.setGroupBy(value));
			dispatch(sqlQueryThunks.getSqlQuery());
		}, []),
		handleSaveSegments: useCallback((value: IResponseSegmentData[]) => {
			dispatch(actions.setSegments(value));
			dispatch(sqlQueryThunks.getSqlQuery());
		}, []),
	};
	function isRequiredValue() {
		return Boolean(events.filter(item => !item.value).length) || !events.length || isLoadingData;
	}

	return (
		<Spin spinning={isLoadingData} tip='Загрузка...'>
			<div className='container'>
				<div className='sidebar'>
					<Typography.Title className='container-title' level={4}>
						Выбор событий
					</Typography.Title>
					<div className='side-bar-content'>
						<div className='buttons-top'>
							<Input
								type='number'
								name='funnelValue'
								className='funnel-param-field'
								placeholder='Выполнено за'
								onChange={debounce(callbacks.handleFunnelChange, 300)}
							/>
							<Select
								className='funnel-granularity-field'
								onChange={debounce(callbacks.handleFunnelGranularityChange, 300)}
								defaultValue='days'
								options={getFunnelGranularityOptions()}
							/>
							<Select
								className='database-field'
								allowClear
								placeholder='Источник запроса'
								showSearch
								value={database}
								onChange={callbacks.handleDbChange}
								options={getOptions<IResponseDatabaseData>(data.data, item => ({
									value: `${item.id}`,
									label: item.name,
								}))}
							/>
							<Select
								className='database-field'
								placeholder='Порядок событий'
								defaultValue='straight'
								onChange={callbacks.handleEventsOrderChange}
								options={[
									{ value: 'straight', label: 'Прямой' },
									{ value: 'strict_order', label: 'Прямой строгий' },
									{ value: 'any', label: 'Любой' },
								]}
							/>
							<Select
								className='database-field'
								placeholder='Измерять как'
								defaultValue='funnel'
								onChange={callbacks.handleMetricsChange}
								options={[
									{ value: MetricTypes.Funnel, label: 'Воронка' },
									{ value: MetricTypes.OverTime, label: 'Конверсия со временем' },
									{ value: MetricTypes.TimeToConvert, label: 'Время в конверсию' },
								]}
							/>
							<div className='container-action-event'>
								<Button onClick={callbacks.handleAddEvent} type='primary' className='action'>
									Добавить событие
								</Button>
							</div>
							<FilterDrawer onSubmit={callbacks.handleSaveFilters} />
							<GroupDrawer onSubmit={callbacks.handleSaveGroupBy} stateIsOpen={stateGroupByIsOpen} setStateIsOpen={setStateGroupByIsOpen} />
							<SegmentsDrawer onSubmit={callbacks.handleSaveSegments} />
							{!dataEvents?.data.rows && !isLoadingData && (
								<Typography.Text className='danger-text' type='danger'>
									Проверьте настройки
								</Typography.Text>
							)}
						</div>
						<div className='buttons-bottom'>
							<div className='container-action-event'>
								<Button
									onClick={callbacks.handleExecuteQuery}
									disabled={isRequiredValue()}
									type='primary'
									className='action'
								>
									Выполнить SQL-запрос
								</Button>
							</div>
							<div className='container-action-event'>
								<SaveModalForm disabled={!Boolean(sqlResponseData && sqlQuery)} />
							</div>
							<div className='container-action-event'>
								<ShowQuestionButton disabled={!Boolean(questionId)} />
							</div>
						</div>
					</div>
				</div>
				<div className='content'>
					<List
						grid={{
							gutter: 16,
							xs: 2,
							sm: 2,
							md: 2,
							lg: 3,
							xl: 3,
							xxl: 4,
						}}
						dataSource={events}
						className='event-list'
						renderItem={item => {
							return <EventCard events={events} setEvents={callbacks.handleSetEvents} item={item} />;
						}}
					/>
				</div>
			</div>
		</Spin>
	);
};
