import { useCallback, useEffect, useState } from 'react';
import { Layout, theme, Button, Menu } from 'antd';
import type { MenuProps } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/store';
import { thunks as collectionThunks } from 'app/store/slices/collection';
import { thunks as databaseThunks } from 'app/store/slices/database';
import { thunks as datasetThunks } from 'app/store/slices/dataset';
import { thunks as sessionThunks } from 'app/store/slices/session';
import { EventList } from './components/EventList';
import { SimpleVisualization } from './components/SimpleVisualization';
import { actions } from 'app/store/slices/sql_query';
import { generateSqlQuery } from 'app/store/slices/sql_query/utils';
import { SettingDrawer } from 'app/pages/HomePage/components/SettingDrawer';

import './styles.css';
import { SegmentsList } from './components/SegmentsList';

export const HomePage = () => {
	const dispatch = useAppDispatch();
	const [stateActiveTab, setStateActiveTab] = useState<string>('1');
	const [current, setCurrent] = useState('1');
	const onClick: MenuProps['onClick'] = e => {
		setCurrent(e.key);
	};
	const { events, funnel, filters, groupby, segments, eventsOrder, metric } = useAppSelector(state => state.event);
	const toSave = false;
	const callbacks = {
		handleChange: useCallback(
			(key: string) => {
				setStateActiveTab(key);
			},
			[stateActiveTab],
		),
		handleLogout: useCallback(() => {
			dispatch(sessionThunks.removeSession());
		}, []),
	};

	const items: MenuProps['items'] = [
		{
			label: 'Воронки',
			key: '1',
		},
		{
			label: 'Сегменты',
			key: '2',
		},
	];

	const {
		token: { colorBgContainer },
	} = theme.useToken();

	useEffect(() => {
		dispatch(collectionThunks.getCollections());
		dispatch(databaseThunks.getDatabases());
		dispatch(datasetThunks.initDataset());
		dispatch(datasetThunks.getFilters());
		dispatch(datasetThunks.getSegments());
		dispatch(datasetThunks.getStaticSegments());
		dispatch(datasetThunks.getEventsPropertiesTypesCatalog());
		dispatch(
			actions.setSqlQuery(generateSqlQuery(events, funnel, filters, groupby, segments, eventsOrder, metric, toSave)),
		);
	}, []);

	const renderLayout = () => {
		switch (current) {
			case '1':
				return <EventList />;
			case '2':
				return <SegmentsList />;
		}
	};

	const renderFooter = () => {
		switch (current) {
			case '1':
				return (
					<Layout.Footer className='footer'>
						<SimpleVisualization activeTab={stateActiveTab} />
					</Layout.Footer>
				);
			case '2':
				return;
		}
	};

	return (
		<Layout className='layout'>
			<Layout.Header className='header'>
				<Menu
					onClick={onClick}
					selectedKeys={[current]}
					mode='horizontal'
					items={items}
					className='menu'
				/>
				<div className='header-actions'>
					<SettingDrawer />
					<Button onClick={callbacks.handleLogout}>Выход</Button>
				</div>
			</Layout.Header>
			<Layout.Content className='content'>
				<div style={{ background: colorBgContainer }} className='site-layout-content'>
					{renderLayout()}
				</div>{' '}
			</Layout.Content>
			{renderFooter()}
		</Layout>
	);
};
